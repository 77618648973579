import styled from "@emotion/styled";
import { useAtomValue } from "jotai";
import React, { useEffect } from "react";
import { CheckSquare, Globe, Home, Icon } from "react-feather";
import { trackEvent } from "../analytics/analyticsHandlers";
import { addToast } from "../components/Toast";
import { useUpdateEditor } from "../editorPage/atomHelpers/editorUpdate";
import { formatInsertedAt } from "../editorPage/utils/insertedAt";
import { editorUpdateAtom, sidebarUpdateAtom } from "../model/atoms";
import { appLocalStore, appNoteStore } from "../model/services";
import { SearchQuerySetter } from "../search/SearchQuery";
import { updateSearchQuery, useSearchQuery } from "../search/useSearchQuery";
import { getShortcutString } from "../shortcuts/shortcuts";
import { useShortcuts } from "../shortcuts/useShortcuts";
import { ALL_NOTES_LINK_ID, TODO_LINK_ID } from "../utils/constants";
import {
  env,
  isAuthEnabled,
  isIOs,
  isMac,
  isPersistenceEnabled,
  isProd,
  isTouchDevice,
  isUploadEnabled,
  lastestCommit,
  modelVersion,
  publicVersion,
  sqliteEnabled,
} from "../utils/environment";
import { colors } from "../utils/style";
import { sendMessageToSW } from "../model/sync/sendMessageToSW";
import { ClientToSWMessageType } from "../service-worker/message/swMessage";
import { EditorStateImporter } from "./EditorStateImporter";
import { FoldersSection } from "./Elements/Folders/FolderSection";
import { HashtagList } from "./Elements/HashtagList";
import { PinnedSection } from "./Elements/Pinned/PinnedSection";
import { ReferencedNotesList } from "./Elements/ReferencedNotesList";
import { SelectedLink } from "./Elements/SelectedLink";
import { SidebarCalendar } from "./SidebarCalendar";
import { countTodos } from "./countUtils";

type Props = {
  children: React.ReactNode;
  id: string;
  label?: string;
  icon: Icon;
  count: number;
  onSelect: () => void;
};

const SidebarLink = React.forwardRef<HTMLDivElement, Props>(
  ({ children, id, label, icon: Icon, count, onSelect }, ref) => {
    const tooltipProps = label
      ? {
          role: "tooltip",
          "data-microtip-position": "bottom",
          "aria-label": label,
        }
      : null;

    return (
      <SelectedLink
        id={id}
        ref={ref}
        tabIndex={0}
        onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === " " || e.key === "Enter") {
            e.preventDefault();
            onSelect();
          }
        }}
        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
          e?.preventDefault();
          onSelect();
        }}
        {...tooltipProps}
      >
        <Icon size={16} id={`${id}-icon`} color={colors.text.accent} />
        {children}
        <span
          style={{
            fontSize: 12,
            marginRight: 4,
            float: "right",
            marginLeft: "auto",
            color: colors.text.secondary,
          }}
        >
          {count.toLocaleString()}
        </span>
      </SelectedLink>
    );
  },
);

export const SidebarContent: React.FC<{
  sidebarFirstFocusableRef: React.RefObject<HTMLDivElement>;
}> = React.memo(({ sidebarFirstFocusableRef }) => {
  const search = useSearchQuery();
  const { shortcuts } = useShortcuts();

  const setSearchFromSidebar: SearchQuerySetter = (state) => {
    updateSearchQuery(state);
    trackEvent("search_from_sidebar");
  };

  // recompute the sidebar (pinned notes, etc.) on new edits
  useAtomValue(sidebarUpdateAtom);
  useAtomValue(editorUpdateAtom);

  // for now, just call this once and pass to the various
  // util functions that count occurrences
  const allNotes = appNoteStore.getAll();

  const datesWithNotes: Set<string> = new Set();
  for (const note of appNoteStore.getAll()) {
    datesWithNotes.add(note.insertedAt);
  }
  const dateHasNotes = (date: Date) => {
    return datesWithNotes.has(formatInsertedAt(date));
  };

  return (
    <>
      <SidebarLink
        id={ALL_NOTES_LINK_ID}
        ref={sidebarFirstFocusableRef}
        label={`All Notes ${getShortcutString(shortcuts.backToAllNotes)}`}
        icon={Home}
        count={allNotes.length}
        onSelect={() => setSearchFromSidebar({ isAll: true })}
      >
        <span>All Notes</span>
      </SidebarLink>

      <SidebarLink
        id={TODO_LINK_ID}
        label={`Add to-dos you can check off\nby typing [ ] in your notes\n${getShortcutString(shortcuts.openTodos)}`}
        icon={CheckSquare}
        count={countTodos(allNotes)}
        onSelect={() =>
          setSearchFromSidebar({
            hasIncomplete: true,
          })
        }
      >
        <span>To-dos</span>
      </SidebarLink>

      <SidebarLink
        id="all-public"
        label={`Notes you've shared publicly\nwith a link appear here`}
        icon={Globe}
        count={allNotes.filter((note) => note.readAll).length}
        onSelect={() => setSearchFromSidebar({ isPublic: true })}
      >
        <span>Public Notes</span>
      </SidebarLink>

      <SidebarCalendar
        search={search}
        setSearchDate={(date) => setSearchFromSidebar({ date: date })}
        dateHasNotes={dateHasNotes}
      />

      <PinnedSection setSearch={setSearchFromSidebar} />

      <FoldersSection />

      <HashtagList setSearch={setSearchFromSidebar} />

      <ReferencedNotesList setSearch={setSearchFromSidebar} />

      {!isProd && <DevSection />}
    </>
  );
});

const DevSectionContainer = styled.div`
  background-color: #e4975d;
  color: white;
  padding: 8px;
  margin-top: 16px;
  font-size: 14px;
`;

/**
 * Expose a global function to simulate an editor refresh.
 *
 * When sync pulls in new notes, the editor gets refreshed, and
 * may have more notes than before. This function is used to simulate
 * that behavior in dev.
 *
 * Example usage to test our ability to handle editor refreshes:
 * - Open the console
 * - Enter `setTimeout(() => simulateEditorRefresh(10), 2000)`
 * - Start typing in the editor
 * - In 2 seconds, the editor will refresh w/ 10 new notes, and you can
 * see if your typing is preserved.
 */
function useExposeSimulateEditorRefresh() {
  const updateEditor = useUpdateEditor();
  useEffect(() => {
    (window as any).simulateEditorRefresh = (n = 0) => {
      const notes = Array.from({ length: n }, () => ({ strings: ["test"] }));
      appNoteStore.insert(notes);
      updateEditor();
    };
  }, [updateEditor]);
}

function DevSection() {
  const deviceInfo = [isMac ? "isMac" : null, isIOs ? "isIOs" : null, isTouchDevice ? "isTouchDevice" : null].filter(
    (flag) => flag !== null,
  );
  const deactivatedFeatures = [
    !isAuthEnabled ? "authentication" : null,
    !isPersistenceEnabled ? "persistence" : null,
    !isUploadEnabled ? "upload" : null,
  ].filter((feat) => feat !== null);
  useExposeSimulateEditorRefresh();

  return (
    <DevSectionContainer>
      <p>Dev info:</p>
      <ul>
        <li>Host: {window.location.hostname}</li>
        <li>Device: {deviceInfo.join(", ")}</li>
        <li>Features deactivated: {deactivatedFeatures.join(", ") || "None"}</li>
        <li>Environment: {env}</li>
        <li>Storage: {sqliteEnabled ? "SQLite" : "IndexedDB"}</li>
        {publicVersion && <li>Version: {publicVersion}</li>}
        {lastestCommit && <li>Latest commit: {lastestCommit}</li>}
      </ul>
      <div>
        <button
          onClick={() => {
            throw new Error("Test error from client. Ignore this.");
          }}
        >
          throw client error
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            sendMessageToSW({
              type: ClientToSWMessageType.SPAM_LONG,
              modelVersion,
            });
          }}
        >
          spam sw with long task
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            const count = 100;
            for (let i = 0; i < count; i++) {
              sendMessageToSW({
                type: ClientToSWMessageType.SPAM_MANY,
                modelVersion,
                total: count,
                current: i,
              });
            }
          }}
        >
          spam sw with many tasks
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            appLocalStore.debugErrorTest();
          }}
        >
          throw sw error
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            addToast({
              type: "error",
              content: (
                <>
                  <div>Test error message which is really long and stuff and mayebe has</div>
                  <div>multiple lines too</div>
                </>
              ),
            });
          }}
        >
          set error message
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            window.location.reload();
          }}
        >
          reload
        </button>
      </div>
      <div>
        <button
          onClick={() => {
            appLocalStore.debugReset();
          }}
        >
          reset sw
        </button>
      </div>
      {!isPersistenceEnabled && <EditorStateImporter />}
    </DevSectionContainer>
  );
}
