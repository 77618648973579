var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0.11.106-e2a00f0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/nextjs";
import { sentryDsn, isProd, sentryRelease, isRunningInIOSWebview, publicVersion, env } from "./src/utils/environment";

const MAX_OFFLINE_SENTRY_LOGS = 500;

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    tracesSampleRate: isProd ? 0.01 : 1.0, // Capture 1% of the transactions in production
    // When we first added this, it cause load times on iOS to increase dramatically.
    // We're not sure why, but we're disabling it for now.
    ...(!isRunningInIOSWebview
      ? {
          replaysSessionSampleRate: 0.1,
          replaysOnErrorSampleRate: 1.0, // Capture replays for 100% of sessions with errors, regardless of env
        }
      : {}),
    normalizeDepth: 5, // Default is 3, but we want to deep than that in sync stats
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: env,
    release: sentryRelease,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
      maxQueueSize: MAX_OFFLINE_SENTRY_LOGS,
    },
    initialScope: {
      tags: {
        publicVersion,
      },
    },
  });
}
